/* eslint-disable no-undef */
import { getAlertExPost, postAlertExPost, getReports, getLatestReport, getOldestReport, getReportTemplate, getReportGEOJSON, getSingleReport, getLegendReport, getReportPdf } from '@/api/services.js';
import { Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import * as olExtent from 'ol/extent';
import * as L from 'leaflet';
import 'leaflet-kml/L.KML';
import * as omnivore from 'leaflet-omnivore';
import * as geotiff from 'leaflet-geotiff/leaflet-geotiff';
import * as plotty from 'leaflet-geotiff/leaflet-geotiff-plotty';
import { getFormattedCurrentDate } from '@/utils.js';
// import { MAPBOX_TOKEN, MAPBOX_STYLE } from '@/constants';
// import * as plottyLib from 'plotty';

// --------------------- STATE -----------------------------

export const state = {
  GeoJSON: new GeoJSON({ featureProjection: 'EPSG:3857' }),
  LLmap: null,
  selectedCity: null,
  selectedAdversity: null,
  alertExp_overlay: false,
  kmlIsPresent: false,
  isSearch: false,
  noPdfFound: false,
  notFound: false,
  crmName: '',
  organization: '',
  searchCityInput: '',
  rangeDate: {},
  headers: [
    {
      text: 'Data misura',
      align: 'start',
      sortable: true,
      value: 'data'
    },
    { text: 'Comune', value: 'nome_comune' }
  ],
  city: [],
  adversity: [],
  pdfList: [],
  region: [],
  tableValues: []
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {};

export const actions = {
  // ------------------------ API ---------------------------
  async getReportsApi({ state, dispatch }, [type, year, page]) {
    try {
      const res = await getReports(true, state.organization.id, type, year, page);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `getReports /${type} ${year} page ${page}`,
          errObj: {
            errors: [{ message: `Error while fetching Reports/${type} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getSingleReportApi({ dispatch }, id) {
    try {
      const res = await getSingleReport(id);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `getSingleReport /${id}`,
          errObj: {
            errors: [{ message: `Error while fetching Single Report/${id} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getLatestReportApi({ state, dispatch }, type) {
    try {
      const res = await getLatestReport(state.organization.id, type);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `getLatestReport /${type}`,
          errObj: {
            errors: [{ message: `Error while fetching Latest Report/${type} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getOldestReportApi({ state, dispatch }) {
    try {
      const res = await getOldestReport(state.organization.id);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getOldestReportYearApi',
          errObj: {
            errors: [{ message: 'Error while fetching Oldest Report Api.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getReportTemplateApi({ dispatch }, templateID) {
    try {
      const res = await getReportTemplate(templateID);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `getReportTemplate /${templateID}`,
          errObj: {
            errors: [{ message: `Error while fetching Report Template/${templateID} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getReportGEOJSONApi({ dispatch }, [reportID, areaID]) {
    try {
      const res = await getReportGEOJSON(reportID, areaID);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getReportGEOJSON',
          errObj: {
            errors: [{ message: `Error while fetching Report GEOJSON/${reportID}/${areaID} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getReportPdfApi({ dispatch }, [organizationID, reportID, digest]) {
    try {
      const res = await getReportPdf(organizationID, reportID, digest);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getReportPdf',
          errObj: {
            errors: [{ message: `Error while fetching Report PDF/${organizationID}/${reportID}/${digest} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getLegendReportApi({ dispatch }, [organizationID, digestLegend]) {
    try {
      const res = await getLegendReport(organizationID, digestLegend);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getLegendReport',
          errObj: {
            errors: [{ message: 'Error while fetching Legend Report Api.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getAlertExPostApi({ state, dispatch }, url) {
    try {
      const res = await getAlertExPost(url);
      res.data.data.forEach(obj => {
        if (url === 'elenco-comuni') {
          state.city.push(obj);
        } else {
          state.adversity.push(obj);
        }
      });
      state.city.sort(function (a, b) {
        return a.nome_comune.toLowerCase() < b.nome_comune.toLowerCase() ? -1 : 1;
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `getAlertExPost /${url}`,
          errObj: {
            errors: [{ message: `Error while fetching Alert Ex-Post/${url} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async postApiData({ dispatch }, [url, body]) {
    try {
      const res = await postAlertExPost(url, body);

      return res;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: `postAlertExPost /${url}`,
          errObj: {
            errors: [{ message: `Error while fetching Alert Ex-Post/${url} Api.` }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ------------------------ REPORTS ---------------------------
  async loadReportGEOJSON({ rootState, state }, geoJson) {
    // String.prototype.convertToRGB = function() {
    //   const aRgbHex = this.match(/.{1,2}/g);
    //   const aRgb = [
    //     parseInt(aRgbHex[0], 16),
    //     parseInt(aRgbHex[1], 16),
    //     parseInt(aRgbHex[2], 16)
    //   ];
    //   return aRgb;
    // };

    // const color = geoJson.geometry.features[0].properties.color.replace("#", "");
    // const rgb = color.convertToRGB();
    // const stroke = new Stroke({color: geoJson.geometry.features[0].properties.color, width: 2});
    // const fill = new Fill({color: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.3)`});
    const strokeWidth = geoJson.geometry.features[0].properties.regione ? 2 : 5;
    const stroke = new Stroke({ color: '#0E7B2D', width: strokeWidth });
    const fill = new Fill({ color: 'rgba(14,123,45,0.3)' });

    const source = new VectorSource();
    const vector = new VectorLayer({
      source: source,
      style: new Style({
        stroke: stroke,
        fill: fill
      })
    });

    vector.set('layer_type', 'REPORTS');
    vector.set('layer_name', geoJson.name);
    rootState.map.OLmap.addLayer(vector);
    source.addFeatures(state.GeoJSON.readFeatures(geoJson.geometry));
    rootState.map.OLmap.getLayers().getArray()[1].getSource().changed();
  },
  fitGEOJSONextent({ rootState }) {
    const extent = olExtent.createEmpty();
    rootState.map.OLmap.getLayers().getArray().slice(1).forEach(function (layer) {
      olExtent.extend(extent, layer.getSource().getExtent());
    });
    rootState.map.OLmap.getView().fit(extent, rootState.map.OLmap.getSize());
  },
  async downloadReport({ dispatch }, item) {
    let type = '';

    switch (item.reportType) {
      case 'forecasting':
        type = '_previsionale';
        break;
      case 'nowcasting':
        type = '_di nowcasting';
        break;
      case 'trend':
        type = '_di tendenza';
        break;
      default:
        break;
    }

    const resp = await dispatch('getReportPdfApi', [item.organization.id, item.id, item.digest]);
    // window.open(URL.createObjectURL(resp.data));
    const link = document.createElement('a');
    link.href = URL.createObjectURL(resp.data);
    link.target = '_blank';
    link.download = `Bollettino${type}_${item.organization.name}_${getFormattedCurrentDate(new Date(item.beginningDate))}.pdf`;
    link.click();
  },
  async downloadReportLegend({ state, dispatch }) {
    const resp = await dispatch('getLegendReportApi', [state.organization.id, state.organization.digestLegend]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(resp.data);
    link.target = '_blank';
    link.download = `Legenda - ${state.organization.name}.pdf`;
    link.click();
  },
  // ------------------------ LEAFLET MAP ---------------------------
  initiateLLMapAction({ rootState, state }) {
    const zoom = rootState.app_user.app_user_data[0].zoom || 0;
    const lat = Number(rootState.app_user.app_user_data[0].latitude) || 0;
    const lon = Number(rootState.app_user.app_user_data[0].longitude) || 0;
    state.LLmap = L.map('map', { zoomControl: false }).setView([lat, lon], zoom);
    // L.tileLayer(`https://api.mapbox.com/styles/v1/${MAPBOX_STYLE.classic}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`, {
    L.tileLayer('https://mt0.google.com/vt/lyrs=m&hl=it&x={x}&y={y}&z={z}', {
      name: 'Map',
      type: 'LLmap'
    }).addTo(state.LLmap);
  },
  loadGeoTIFFlayer({ state }, respGeotiff) {
    const options = {
      band: 0,
      name: respGeotiff.data.data.nome,
      type: 'ALERT_EXP_GeoTIFF',
      opacity: 0.3,
      renderer: new plotty.plotty({ colorScale: 'alert_ex_post' }),
      transparent: true,
      format: 'image/png'
      // renderer: L.LeafletGeotiff.plotty({ colorScale: 'alert_ex_post' })
    };

    const geotiffLayer = new geotiff.LeafletGeotiff(
      respGeotiff.data.data.geotiff_url,
      options
    );
    geotiffLayer.addTo(state.LLmap);
  },
  loadKmlLayer({ state }, respKml) {
    const customLayer = L.geoJSON(null, {
      style: function() {
        return {
          color: '#000',
          fillColor: '#636363',
          fillOpacity: 0.3
        };
      },
      name: respKml.data.data.nome_comune,
      type: 'ALERT_EXP_KML'
    });

    const kmlLayer = omnivore.kml(
      respKml.data.data.kml_url,
      null,
      customLayer
    );
    kmlLayer.addTo(state.LLmap);

    kmlLayer.on('ready', () => {
      state.LLmap.fitBounds(kmlLayer.getBounds());
    });
  },
  // ------------------------ ALERT EX-POST ---------------------------
  async searchBtn({ state, dispatch }, rangeDate) {
    state.alertExp_overlay = true;
    state.rangeDate = rangeDate;
    state.isSearch = true;

    state.LLmap.eachLayer(layer => {
      if (layer.options.name !== 'Map') {
        state.LLmap.removeLayer(layer);
      }
    });

    const respKml = await dispatch('postApiData', ['dettaglio-comune', {
      id: state.selectedCity.id,
      cod_ident_comune: state.selectedCity.cod_ident_comune
    }]);

    const respGeotiff = await dispatch('postApiData', ['dettaglio-variabile', {
      cod_avversita: state.selectedAdversity.cod_avversita,
      data: rangeDate.endDate
    }]);

    dispatch('loadKmlLayer', respKml);

    if (respGeotiff.data.data.geotiff_url) {
      state.notFound = false;
      dispatch('loadGeoTIFFlayer', respGeotiff);
    } else {
      state.notFound = true;
      // state.isSearch = false;
    }

    state.alertExp_overlay = false;
  },
  async skipBtn({ state, dispatch }, date) {
    state.kmlIsPresent = false;
    state.LLmap.eachLayer(layer => {
      if (layer.options.type === 'ALERT_EXP_GeoTIFF') {
        state.LLmap.removeLayer(layer);
      }

      if (layer.options.type === 'ALERT_EXP_KML') {
        state.kmlIsPresent = true;
      }
    });

    const respGeotiff = await dispatch('postApiData', ['dettaglio-variabile', {
      cod_avversita: state.selectedAdversity.cod_avversita,
      data: date
    }]);

    if (respGeotiff.data.data.geotiff_url) {
      state.notFound = false;
      // state.isSearch = true;

      if (!state.kmlIsPresent) {
        const respKml = await dispatch('postApiData', ['dettaglio-comune', {
          id: state.selectedCity.id,
          cod_ident_comune: state.selectedCity.cod_ident_comune
        }]);
        dispatch('loadKmlLayer', respKml);
      }

      dispatch('loadGeoTIFFlayer', respGeotiff);
    } else {
      state.notFound = true;
      // state.isSearch = false;
    }
  },
  async viewTable({ state, dispatch }) {
    state.headers = [
      {
        text: 'Data misura',
        align: 'start',
        sortable: true,
        value: 'data'
      },
      { text: 'Comune', value: 'nome_comune' }
    ];
    state.tableValues = [];

    const resp = await dispatch('postApiData', ['valori-avversita', {
      cod_avversita: state.selectedAdversity.cod_avversita,
      cod_ident_comune: state.selectedCity.cod_ident_comune,
      da: state.rangeDate.startDate,
      a: state.rangeDate.endDate
    }]);

    if (resp.data.data.values.length > 0) {
      Object.keys(resp.data.data.headers).forEach(header => {
        // if (Object.keys(resp.data.data.values[0]).includes(header)) {
        state.headers.push({
          text: resp.data.data.headers[header],
          value: header
        });
        // }
      });

      resp.data.data.values.forEach(value => state.tableValues.push(value));

      state.tableValues.forEach(obj => {
        Object.keys(obj).forEach(item => {
          if (!Object.keys(resp.data.data.headers).includes(item) && item !== 'data' && item !== 'nome_comune') {
            delete obj[item];
          }
        });
      });
    }

  },
  async getReportList({ state, dispatch }, data) {
    state.pdfList = [];
    const resp = await dispatch('postApiData', ['pdf-in-date-range', {
      stato_code: data.state,
      regione_code: data.region,
      cod_avversita: data.adversity.cod_avversita,
      date_from: data.startDate,
      date_to: data.endDate
    }]);

    if (resp.data.data !== 'Nessun pdf presente nel range di date cercato.') {
      state.noPdfFound = false;
      resp.data.data.forEach(item => state.pdfList.push(item));
    } else {
      state.noPdfFound = true;
    }
  },
  async downloadPdf({ dispatch }, [pdfName, fileName]) {
    const resp = await dispatch('postApiData', ['download-pdf', { nome_file: pdfName }]);
    const link = document.createElement('a');
    link.href = resp.data.data;
    link.target = '_blank';
    link.download = `${fileName}.pdf`;
    link.click();
  }
};

export const getters = {};

<template>
  <v-flex
    offset-lg-1
    class="flex-margin"
  >
    <v-card v-if="!isHTML">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title
        class="card-title v-card-scroll"
        color="black"
      >
        {{ $t('Reports.name') }}
        <v-spacer />
        <v-icon
          v-if="mobileBreakpoints()"
          color="red"
          size="28"
          @click="$router.push('/')"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <v-tabs
        v-model="selectedTab"
        color="green"
        active-class="white"
        background-color="#f4f3f3"
        grow
      >
        <v-tab
          v-for="(type, index) in reportType"
          :key="index"
        >
          {{ type.label }}
        </v-tab>
      </v-tabs>

      <v-row
        v-if="selectedTab > 0"
        class="ms-1 mt-3 me-5"
      >
        <v-col cols="4">
          <v-select
            v-model="selectedYear"
            :hide-details="mobileBreakpoints()"
            :items="yearsArray"
            label="Year"
          />
        </v-col>
        <v-col
          cols="8"
          sm="4"
        >
          <v-menu
            ref="filterReportsMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="rangeDateText"
                :hide-details="mobileBreakpoints()"
                multiple
                chips
                small-chips
                label="Filter range date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                class="shrink"
                clearable
                @click:clear="closeDateRange()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              :picker-date.sync="pickerDate"
              :allowed-dates="filterAllowedDates"
              no-title
              range
              scrollable
              :min="rangeDate[0]"
              :max="moment().format('YYYY-MM-DD')"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="changeFilterDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          :class="mobileBreakpoints() ? 'mb-4 no-margin-top' : ''"
        >
          <v-text-field
            v-model="search"
            class="shrink"
            clearable
            prepend-icon="mdi-magnify"
            :label="$t('AlertExPost.search')"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(type, index) in reportType"
          :key="index"
        >
          <v-card>
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              :search="search"
              :custom-filter="customSearch"
              sort-by="reportDate"
              :sort-desc="true"
              class="elevation-1"
              :footer-props="{
                itemsPerPageAllText: $t('Table.all'),
                itemsPerPageText: $t('Table.rowsPerPage'),
                pageText: `{0}-{1} ${$t('Table.of')} {2}`
              }"
              :no-data-text="$t('Table.noData')"
            >
              <template v-slot:[`item.reportType`]="{item}">
                {{ $t(`Reports.${item.reportType}`).toUpperCase() }}
              </template>
              <template v-slot:[`item.reportDate`]="{item}">
                {{ moment(item.reportDate).format('DD/MM/YYYY HH:mm') }}
              </template>
              <!-- <template v-slot:top>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('AlertExPost.search')"
                  class="mx-5 mb-5 mt-0 pt-5"
                  single-line
                  hide-details
                />
              </template> -->
              <template v-slot:[`item.view`]="{ item }">
                <v-btn
                  small
                  color="success mr-2"
                  dark
                  @click="viewReport(item)"
                >
                  HTML
                </v-btn>

                <v-btn
                  class="me-3"
                  small
                  outlined
                  color="success"
                  dark
                  @click="downloadReport(item)"
                >
                  PDF
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      v-else
      class="mb-8"
    >
      <v-card-title
        class="card-title v-card-scroll d-flex justify-space-between"
        :class="!mobileBreakpoints() ? 'flex-row-reverse' : ''"
        color="black"
      >
        <div
          class="d-flex"
          :style="mobileBreakpoints() ? 'width: 100%;' : 'width: auto;'"
        >
          <v-btn
            outlined
            light
            color="success"
            :small="mobileBreakpoints()"
            @click="isHTML=false"
          >
            <v-icon
              size="18"
              class="mr-2"
            >
              mdi-arrow-collapse-left
            </v-icon>
            {{ $t('Reports.list') }}
          </v-btn>
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            class="text-right"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </div>
        <div :class="mobileBreakpoints() ? 'mt-4' : 'mt-0'">
          Bollettino del {{ moment(report.reportDate).format('DD/MM/YYYY HH:mm') }}
        </div>
      </v-card-title>
      <reports-detail
        :report="report"
        :geojson="geojsonResp.filter(e => e !== undefined)"
        :template-headers="templateHeaders"
        :location-headers="locationHeaders"
      />
    </v-card>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getReportOrganization } from '@/api/services.js';
import moment from 'moment-business-days';
import { svgLegend } from '@/svg.js';

export default {
  components: {
    ReportsDetail: () => import('@/components/utils/ReportsDetail')
  },
  data: () => ({
    legendSvg: svgLegend,
    search: null,
    pickerDate: null,
    areaID: '',
    rangeMenu: false,
    isHTML: false,
    overlay: false,
    report: {},
    selectedTab: {},
    rangeDate: [],
    geojsonResp: [],
    templateHeaders: [],
    itemsArray: [],
    latestReportsArray: [],
    forecastingArray: [],
    nowcastingArray: [],
    trendArray: [],
    reportType: [],
    headers: [],
    locationHeaders: [],
    yearsArray: [],
    allowedDates: [],
    selectedYear: ''
  }),

  computed: {
    ...mapState(['app_user', 'map', 'other_services']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? moment(this.rangeDate[0]).format('DD/MM/YYYY') : '';
      const end_date = this.rangeDate[1] ? moment(this.rangeDate[1]).format('DD/MM/YYYY') : '';

      return this.rangeDate.length ? `${start_date} - ${end_date}` : '';
    },
    filteredItems() {
      return this.rangeDate.length < 2
        ? this.itemsArray
        : this.itemsArray.filter(e => {
          const start = moment(this.rangeDate[0]).format('YYYY-MM-DD');
          const end = moment(this.rangeDate[1]).format('YYYY-MM-DD');

          return moment(e.reportDate, 'YYYY-MM-DD').isSameOrAfter(start, 'day') && moment(e.reportDate, 'YYYY-MM-DD').isSameOrBefore(end, 'day');
        });
    }
  },

  watch: {
    selectedTab(newTab) {
      this.isHTML = false;
      const selectedType = this.reportType[newTab].type;
      this.itemsArray = this.getItemsArray(selectedType);
      this.removeEveryLayer();
    },
    async selectedYear(newYear) {
      if (newYear) {
        this.overlay = true;
        this.search = null;
        this.rangeDate = [];
        this.pickerDate = `${newYear}-01`;
        this.allowedDates = this.fetchAllowedDates();
        await this.fetchReports(newYear);
        this.itemsArray = this.getItemsArray(this.reportType[this.selectedTab].type);
        this.overlay = false;
      }
    }
  },

  async created () {
    this.overlay = true;
    this.other_services.crmName = this.app_user.app_user_data[0].ragione_sociale;
    // this.other_services.crmName = "TEST - Reply";
    // this.other_services.crmName = "Radarmeteo";
    this.reportType = [
      { type: 'LatestReports', label: this.$t('Reports.latestReports') },
      { type: 'Forecasting', label: this.$t('Reports.forecasting') },
      { type: 'Nowcasting', label: this.$t('Reports.nowcasting') },
      { type: 'Trend', label: this.$t('Reports.trend') }
    ];
    this.headers = [
      { text: this.$t('Reports.type'), value: 'reportType', sortable: true },
      { text: this.$t('Reports.num'), value: 'number', sortable: true },
      { text: this.$t('Reports.date'), value: 'reportDate', sortable: true },
      { text: this.$t('Reports.view'), value: 'view', align: 'end', sortable: false }
    ];

    const organizationsResp = await getReportOrganization();
    this.other_services.organization = organizationsResp.data.items.find(item => item.name === this.other_services.crmName);

    for await (const type of this.reportType) {
      if (type.type !== 'LatestReports') {
        const latestReportResp = await this.getLatestReportApi(type.type);

        if (latestReportResp.data.items.length > 0) {
          this.latestReportsArray.push(latestReportResp.data.items[0]);
        }
      }
    }

    const oldestYearResp = await this.getOldestReportApi();
    this.yearsArray = this.generateYearsArray(moment(oldestYearResp.data.items[0].reportDate).format('YYYY'));
    this.selectedYear = this.yearsArray[0];
    this.pickerDate = `${this.selectedYear}-01`;
    this.allowedDates = this.fetchAllowedDates();
    this.overlay = false;
  },

  methods: {
    ...mapActions(['getReportsApi', 'getLatestReportApi', 'getOldestReportApi', 'getReportTemplateApi', 'getReportGEOJSONApi', 'loadReportGEOJSON', 'downloadReport', 'removeEveryLayer', 'fitGEOJSONextent', 'getSingleReportApi']),
    customSearch(value, item) {
      const translatedValue = this.$t(`Reports.${value}`).toLowerCase();

      return String(translatedValue).toLowerCase().includes(String(item).toLowerCase());
    },
    getItemsArray(type) {
      switch (type) {
        case 'Forecasting':
          return this.forecastingArray;
        case 'Nowcasting':
          return this.nowcastingArray;
        case 'Trend':
          return this.trendArray;
        default:
          return this.latestReportsArray;
      }
    },
    async viewReport(item) {
      this.overlay = true;
      let templateResp = '';
      let singleReportResp = '';

      if (item) {
        templateResp = await this.getReportTemplateApi(item.reportTemplateId);
        singleReportResp = await this.getSingleReportApi(item.id);
        // } else {
        //   const latestReportResp = this.enabledType.type === 'LatestReports'
        //     ? await this.getLatestReportApi(this.capitalizeFirstLetter(this.latestReportsArray[0].reportType))
        //     : await this.getLatestReportApi(this.enabledType.type);

      //   templateResp = await this.getReportTemplateApi(latestReportResp.data.items[0].reportTemplateId);
      //   singleReportResp = await this.getSingleReportApi(latestReportResp.data.items[0].id);
      }

      this.report = singleReportResp.data;

      this.geojsonResp = [];
      this.templateHeaders = [];
      this.locationHeaders = [];

      for await (const section of templateResp.data.sections) {
        this.locationHeaders.push(section.name);
        const headers = ['Fascia'];

        for await (const area of section.subSections) {
          this.geojsonResp.push(await this.getReportGEOJSONApi([singleReportResp.data.id, area.id]));
          headers.push(area.name);
        }

        this.templateHeaders.push(headers);
      }

      this.isHTML = false;
      this.$nextTick(() => {
        this.isHTML = true;
      });

      await this.removeEveryLayer();

      if (this.map.OLmap.getLayers().getArray().slice(1).length > 0) {
        const promises = [];
        this.geojsonResp.forEach(item => {
          promises.push(this.loadReportGEOJSON(item.data));
        });
        await Promise.all(promises);
        this.fitGEOJSONextent();
      }

      this.overlay = false;
    },
    changeFilterDate() {
      this.$refs.filterReportsMenu.save(this.rangeDate);
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.rangeDate = [];
      this.$refs.filterReportsMenu.save([]);
    },
    async fetchReports(year) {
      const reportTypeToArrayMap = {
        Forecasting: 'forecastingArray',
        Nowcasting: 'nowcastingArray',
        Trend: 'trendArray'
      };

      await Promise.all(
        this.reportType
          .filter(type => type.type !== 'LatestReports')
          .map(async (type) => {
            const items = [];
            let totalCount = 0;
            let page = 0;

            do {
              page += 1;
              const resp = await this.getReportsApi([type.type, year, page]);
              items.push(...resp.data.items);
              totalCount = resp.data.paging.totalCount;
            } while (items.length < totalCount);

            if (reportTypeToArrayMap[type.type]) {
              this[reportTypeToArrayMap[type.type]] = items;
            }
          })
      );
    },
    fetchAllowedDates() {
      const datesArray = [];
      const startOfYear = moment(this.selectedYear, 'YYYY').startOf('year');
      const endOfYear = moment(this.selectedYear, 'YYYY').endOf('year');

      const currentDate = startOfYear.clone();

      while (currentDate.isSameOrBefore(endOfYear)) {
        datesArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
      }

      return datesArray;
    },
    filterAllowedDates(date) {
      return this.allowedDates.includes(date);
    },
    generateYearsArray(startYear) {
      const currentYear = moment().format('YYYY');

      return Array.from({ length: parseInt(currentYear) - parseInt(startYear) + 1 }, (_, i) => parseInt(startYear) + i).reverse();
    },
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>
.flex-margin{
  margin-right: 2%;
  margin-top: 2%
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.card {
  margin-right:5%;
}
.no-margin-top {
  margin-top: -24px;
}

@media (max-device-width: 599px) {
  .flex-margin {
    margin-bottom: 2%;
  }
}

@media (min-device-width: 600px) {
  .flex-margin {
    margin-left: calc(100px + 2%);
  }
}

</style>

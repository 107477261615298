<template>
  <v-row class="pl-1 py-1 d-block">
    <v-btn
      :color="measureToolActive ? 'RMblack' : 'RMwhite'"
      :class="`pa-0 ${measureToolActive ? 'border-top-radius-class' : 'border-radius-class'}`"
      min-height="40"
      min-width="40"
      @click="setMeasureTool('')"
    >
      <v-icon
        :title="$t('Measure.tool')"
        :color="measureToolActive ? 'grey' : 'black'"
      >
        mdi-ruler
      </v-icon>
    </v-btn>
    <v-divider
      class="ma-0 pa-0"
      width="40"
    />
    <div v-if="measureToolActive">
      <v-btn
        :color="distanceSelected ? 'grey lighten-1' : 'RMwhite'"
        class="pa-0 rounded-0"
        min-height="40"
        min-width="40"
        @click="setMeasureTool('distance')"
      >
        <v-icon
          :title="$t('Measure.distance')"
          color="black"
        >
          mdi-chart-timeline-variant
        </v-icon>
      </v-btn>
      <v-divider
        class="ma-0 pa-0"
        width="40"
      />
      <v-btn
        :color="areaSelected ? 'grey lighten-1' : 'RMwhite'"
        class="pa-0 border-bottom-radius-class"
        min-height="40"
        min-width="40"
        @click="setMeasureTool('area')"
      >
        <v-icon
          :title="$t('Measure.area')"
          color="black"
        >
          mdi-rectangle-outline
        </v-icon>
      </v-btn>
    </div>
    <v-snackbar
      v-model="snackbar"
      timeout="-1"
      color="RMblack"
    >
      <span
        id="snackbar"
        class="font-weight-bold"
      >
        {{ areaSelected && map.measureToolValue !== '' ? $t('Measure.area') : '' }}{{ distanceSelected && map.measureToolValue !== '' ? $t('Measure.distance') : '' }}: {{ map.measureToolValue }}<sup v-if="areaSelected && map.measureToolValue !== ''">2</sup>
      </span>

      <template v-slot:action="{ attrs }">
        <v-icon
          color="red"
          size="30"
          class="mt-1"
          v-bind="attrs"
          @click="setMeasureTool('')"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
  },
  data: () => ({
    snackbar: false,
    measureToolActive: false,
    areaSelected: true,
    distanceSelected: false
  }),
  computed: {
    ...mapState(['map'])
  },
  watch: {
    $route() {},
    '$route.params': {
      async handler(newValue) {
        if (newValue) {
          if (newValue.id) {
            this.areaSelected = false;
            this.distanceSelected = false;
            this.snackbar = false;
            this.measureToolActive = false;
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['toggleMeasureTool']),
    setMeasureTool(toolType) {
      switch (toolType) {
        case 'distance':
          this.snackbar = true;
          this.areaSelected = false;
          this.distanceSelected = true;
          break;
        case 'area':
          this.snackbar = true;
          this.areaSelected = true;
          this.distanceSelected = false;
          break;
        default:
          this.areaSelected = false;
          this.distanceSelected = false;
          this.snackbar = false;
          this.measureToolActive = !this.measureToolActive;
          break;
      }

      this.toggleMeasureTool(toolType);
    }
  }
};
</script>

<template>
  <v-app
    id="app-vue"
    :style="{ background: $vuetify.theme.themes['light'].background }"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div>
      <toast position="ne" />
    </div>
    <div
      v-if="!isAuthenticated"
      name="auth-container"
      class="signin-class"
    >
      <v-row class="d-block">
        <v-row>
          <v-spacer />
          <v-col
            class="d-block text-center pt-6"
            cols="10"
            md="6"
          >
            <v-img
              src="@/assets/logonegativo.png"
              width="10em"
              class="d-inline-block"
              fixed
            />
            <h1 class="white--text mt-5">
              MeteoCast®
            </h1>
            <p class="white--text">
              Piattaforma integrata di supporto meteorologico
            </p>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <SignIn
            @log-in="logInActions($event)"
            @is-authenticated="isAuthenticated = $event"
          />
          <!-- <amplify-authenticator
            :auth-config="{ signInConfig: { isSignUpDisplayed: false } }"
          >
            <amplify-sign-in
              slot="sign-in"
              class="custom-sign-in"
              :header-text="$t('Signin.title')"
              hide-sign-up="true"
              :form-fields.prop="formFields"
            />
          </amplify-authenticator> -->
          <v-spacer />
        </v-row>
      </v-row>
    </div>
    <div
      v-if="isAuthenticated && user"
      style="height: 100%;"
    >
      <NavigationSideBar />
      <NavigationDrawer v-if="!mobileBreakpoints()" />
      <span v-if="$route.path == '/'">
        <TopLeftSection />
        <TopRightSection v-if="mobileBreakpoints()" />
        <BottomSection />
      </span>
      <span v-if="$route.path.includes('map')">
        <TopLeftSection />
        <TopRightSection />
        <BottomSection />
      </span>
      <span v-if="$route.path.includes('ticketing-request')">
        <TopLeftSection />
        <TopRightSection v-if="mobileBreakpoints()" />
        <TicketingRequest />
      </span>
      <span v-if="$route.path.includes('alert-ex-post')">
        <TopRightSection />
      </span>
      <v-container
        :style="{ background: $vuetify.theme.themes['light'].background }"
        fluid
        class="pa-0 containerBox"
      >
        <v-fade-transition mode="out-in">
          <router-view v-if="app_user.app_user_data != null" />
        </v-fade-transition>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { Toast } from 'vuex-toast';
// import { AuthState } from '@aws-amplify/ui-components';
import { mapActions, mapState } from 'vuex';
import TopRightSection from '@/components/core/TopRightSection';
import TopLeftSection from '@/components/core/TopLeftSection';
import NavigationDrawer from '@/components/core/NavigationDrawer';
import BottomSection from '@/components/core/BottomSection';
import NavigationSideBar from '@/components/core/NavigationSideBar';
import TicketingRequest from '@/views/TicketingRequest';
import SignIn from '@/views/SignIn.vue';
import { loadLanguageAsync } from '@/plugins/i18n.js';
import * as api from '@/api/api.js';
import { nestHierarchyItems, localStorageOperations, GET, SET, DEL } from '@/utils.js';
import { S3_BUCKETS } from '@/constants.js';
import { Auth, Hub } from 'aws-amplify';
import Holidays from 'date-holidays';
import moment from 'moment-business-days';

export default {
  name: 'App',
  components: {
    TopRightSection,
    TopLeftSection,
    NavigationDrawer,
    BottomSection,
    Toast,
    NavigationSideBar,
    TicketingRequest,
    SignIn
  },
  data() {
    return {
      overlay: false,
      sendAccessEvent: false,
      isAuthenticated: false,
      user: undefined
    };
  },
  computed: {
    ...mapState(['policy', 'app_user', 'map', 'customer', 'drawer', 'ticketing', 'other_services', 'global_layers'])
  },
  watch: {
    $route (to, from) {
      if (from.path.substring(1) === 'alert-ex-post') {
        this.other_services.isSearch = false;
      }

      if (this.app_user.defaultService.GLOBAL?.service_path === from.path.substring(1)) {
        this.app_user.defaultService.GLOBAL = {};
      }

      if (from.params.id === 'Forecast' || from.params.id === 'Real-Time') {
        Object.values(this.map.refreshIntervalID).forEach(item => clearInterval(item));
        this.map.refreshIntervalID = {};
      }

      if (from.params.id === 'Forecast' || from.params.id === 'Real-Time' || from.params.id === 'Archive') {
        this.map.specificDefaultService[from.params.id.replace('-', '')] = false;

        if (to.params.id !== 'Forecast' && to.params.id !== 'Real-Time' && to.params.id !== 'Archive') {
          this.removeEveryLayer();
        }
      }

      const macroAreas = ['Map', 'TicketingRequest'];

      if (macroAreas.includes(from.name) && !macroAreas.includes(to.name)) {
        this.global_layers.selectedGlobalLayer = [];
      }
    }
  },
  async created() {
    await this.checkAuthState();
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        this.sendAccessEvent = true;
      }
    });
  },
  // beforeDestroy() {
  //   return onAuthUIStateChange;
  // },
  methods: {
    ...mapActions([
      'setCredentials',
      'refreshToken',
      'storeAppUser',
      'getLogo',
      'storeEnabledServices',
      'storeCustomerManuals',
      'storeAppUserPolicy',
      'storeEnabledServicesPolicy',
      'storeGlobalLayers',
      'storeCustomerLayers',
      'storeDefaultService',
      'removeEveryLayer'
    ]),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    },
    async checkAuthState() {
      return Auth.currentAuthenticatedUser()
        .then(async authData => {
          this.isAuthenticated = true;
          await this.logInActions(authData);
        })
        .catch(() => {
          this.isAuthenticated = false;
        });
    },
    async logInActions(authData) {
      this.user = authData;

      if (this.user) {
        if (this.user.challengeName != 'NEW_PASSWORD_REQUIRED') {
          if (this.user.signInUserSession) {
            if (authData.attributes.locale) {
              if (this.user.signInUserSession.idToken) {
                this.app_user.app_user_id = authData.attributes.locale;
                this.app_user.username = authData.username;
                await this.storeAppUser(this.app_user.app_user_id);
                this.app_user.crm_id = this.app_user.app_user_data[0].crm_id;
                this.map.mapStyle = this.app_user.app_user_data[0].user_map_style || this.app_user.app_user_data[0].map_style;
                await this.setCredentials();
                this.overlay = true;
                await this.setLanguage();
                await this.setDefaultService();
                this.overlay = false;

                if (this.sendAccessEvent) {
                  this.sendAccessEvent = false;
                  this.$gtag.event('Accesso', {
                    event_category: this.app_user.crm_id,
                    event_label: `${Date.now()} - ${moment().format('DD-MM-YYYY HH:mm:ss')}`,
                    value: this.app_user.username
                  });
                }

                this.storeEnabledServices(this.app_user.app_user_id);
                this.storeCustomerManuals(this.app_user.crm_id);
                await this.refreshToken();
                await this.dispatchDownloadLogo();
                this.checkHolidays();
              } else {
                api.logOff();
                this.$router.push('/');
                location.reload();
              }
            } else {
              api.logOff();
            }
          } else {
            api.logOff();
            this.$router.push('/');
            location.reload();
          }
        }
      }
    },
    async dispatchDownloadLogo() {
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: `${this.app_user.app_user_data[0].logo}`
      };

      if (this.app_user.app_user_data[0].logo) {
        await this.getLogo(params);
      }
    },
    async checkLocalStorageDefaultService() {
      const localStorageLabel = (scope) => `defaultService.${scope}.${this.app_user.crm_id}`;
      const APP_SCOPE_TYPES = ['GLOBAL', 'Archive', 'RealTime', 'Forecast'];
      const defaultServiceData = {
        customer_id: this.app_user.crm_id,
        timeStamp_token: {}
      };

      APP_SCOPE_TYPES.forEach(scope => {
        defaultServiceData.timeStamp_token[scope] = localStorageOperations(GET, `${localStorageLabel(scope)}.TOKEN`) || '0';
      });

      await this.storeDefaultService(defaultServiceData);

      if (Object.values(this.app_user.defaultService).some(item => item?.default_id === '' && item?.timeStamp_token !== null)) {
        APP_SCOPE_TYPES.forEach(scope => {
          this.app_user.defaultService[scope] = localStorageOperations(GET, `${localStorageLabel(scope)}.OBJ`);
        });
      } else {
        APP_SCOPE_TYPES.forEach(scope => {
          if (this.app_user.defaultService[scope]?.timeStamp_token || scope === 'GLOBAL') {
            localStorageOperations(SET, `${localStorageLabel(scope)}.TOKEN`, this.app_user.defaultService[scope]?.timeStamp_token);
            localStorageOperations(SET, `${localStorageLabel(scope)}.OBJ`, this.app_user.defaultService[scope]);
          } else {
            localStorageOperations(DEL, `${localStorageLabel(scope)}.TOKEN`);
            localStorageOperations(DEL, `${localStorageLabel(scope)}.OBJ`);
          }
        });
      }
    },
    async setDefaultService() {
      await this.checkLocalStorageDefaultService();

      if (this.app_user.defaultService.GLOBAL?.default_id && this.app_user.defaultService.GLOBAL?.default_id !== '') {
        const SERVICE_TYPE = this.app_user.defaultService.GLOBAL.service_type;
        this.drawer.drawerTitle = this.app_user.defaultService.GLOBAL.drawer_title;
        const wsGroup = SERVICE_TYPE === 'WS' ? this.app_user.defaultService.GLOBAL.service_path.replace('map/', '') : null;
        const data = {
          app_user_id: this.app_user.app_user_id,
          service_type: SERVICE_TYPE,
          ws_group: wsGroup
        };
        await this.storeEnabledServicesPolicy(data);

        const params = {};

        if (SERVICE_TYPE === 'URL' || SERVICE_TYPE === 'DOC' || SERVICE_TYPE === 'REPORT-HUB' || SERVICE_TYPE === 'TICKET') {
          const findServiceByType = {
            TICKET: () => '',
            'REPORT-HUB': () => this.policy.enabledServicesPolicy.REPORT_HUB?.find(item => item.service_id === this.app_user.defaultService.GLOBAL.service_details[0].service_id) || '',
            default: () => this.policy.enabledServicesPolicy[SERVICE_TYPE]?.find(item => item.service_id === this.app_user.defaultService.GLOBAL.service_details[0].service_id) || ''
          };
          const findService = (findServiceByType[SERVICE_TYPE] || findServiceByType.default)();

          params.policy_id = SERVICE_TYPE === 'TICKET' ? null : findService.policy_id;
          params.service_id = this.app_user.defaultService.GLOBAL.service_details[0].service_id;
          params.service_type = SERVICE_TYPE;
        }

        switch (SERVICE_TYPE) {
          case 'WS':
            this.drawer.workspaceSidebar = true;
            this.drawer.disableWorkspaceSidebar = false;
            await this.storeCustomerLayers(this.app_user.crm_id);
            await this.storeGlobalLayers(this.policy.enabledServicesPolicy.WS.map(ws => ws.service_id));
            break;
          case 'ALERT EX-POST':
            this.drawer.workspaceSidebar = true;
            this.drawer.disableWorkspaceSidebar = false;
            this.policy.translationPath = `ServiceCard.${this.app_user.defaultService.GLOBAL.service_details[0].service_id}.title`;
            this.policy.translationPathDescription = `ServiceCard.${this.app_user.defaultService.GLOBAL.service_details[0].service_id}.description`;
            break;
          case 'REPORT-HUB':
            this.app_user.defaultService.GLOBAL.service_path += this.app_user.defaultService.GLOBAL.service_details[0].service_id;
            await this.storeAppUserPolicy(params);
            break;
          case 'URL':
          case 'DOC':
            this.app_user.defaultService.GLOBAL.service_path += this.app_user.defaultService.GLOBAL.service_details[0].service_id;
            await this.storeAppUserPolicy(params);
            this.policy.appUserPolicy.forEach(policyItem => {
              this.policy.hierarchyItems = nestHierarchyItems(policyItem[SERVICE_TYPE]);
            });
            this.policy.translationPath = `ServiceCard.${this.app_user.defaultService.GLOBAL.service_details[0].service_id}.title`;
            this.policy.translationPathDescription = `ServiceCard.${this.app_user.defaultService.GLOBAL.service_details[0].service_id}.description`;
            break;
          case 'TICKET':
            this.app_user.defaultService.GLOBAL.service_path += this.app_user.defaultService.GLOBAL.service_details[0].service_id;
            await this.storeAppUserPolicy(params);
            this.policy.ticketingServicePermissions = this.policy.enabledServicesPolicy.TICKET.permissions;

            if (this.policy.enabledServicesPolicy.TICKET.enabled_service[0] != undefined) {
              this.ticketing.app_user_ticket_id = this.policy.enabledServicesPolicy.TICKET.enabled_service[0].app_user_ticket_id;
            }

            this.policy.ticketingServicePermissions.forEach(item => {
              item.app_user_id = this.app_user.app_user_id;
            });
            this.policy.appUserPolicy.forEach(policyItem => {
              this.ticketing.appUserPolicyTicketing = policyItem.TICKET;
            });
            break;
          default:
            break;
        }

        if (this.$route.path === '/') {
          if (SERVICE_TYPE === 'REPORT-HUB') {
            this.$router.push({
              name: 'Report-Hub',
              params: { id: this.app_user.defaultService.GLOBAL.service_details[0].service_id }
            });
          } else {
            this.$router.push(this.app_user.defaultService.GLOBAL.service_path);
          }
        }
      }
    },
    checkHolidays() {
      const thisYear = moment().format('YYYY');
      const checkThisYearHolidays = new Holidays('IT').getHolidays(thisYear);
      const thisYearHolidays = checkThisYearHolidays.map(day => day.date.split(' ')[0]);
      const nextYear = moment().add(1, 'years').format('YYYY');
      const checkNextYearHolidays = new Holidays('IT').getHolidays(nextYear);
      const nextYearHolidays = checkNextYearHolidays.map(day => day.date.split(' ')[0]);
      moment.updateLocale('it', {
        holidays: thisYearHolidays.concat(nextYearHolidays),
        holidayFormat: 'YYYY-MM-DD',
        workingWeekdays: [1, 2, 3, 4, 5],
        nextBusinessDayLimit: 31
      });
    },
    async setLanguage() {
      const localStorageKey = `lang_${this.app_user.app_user_id}`;
      const localStorageLang = localStorageOperations(GET, localStorageKey);
      const language = localStorageLang ? localStorageLang : 'it';
      await loadLanguageAsync(language);
      const userLang = this.app_user.app_user_data[0].language;
      localStorageOperations(SET, localStorageKey, userLang.toLowerCase());

      if (userLang.toLowerCase() !== localStorageLang) {
        loadLanguageAsync(userLang.toLowerCase());
      }

      this.map.mapLocale = userLang === 'it' ? 'Italian' : 'English';
      this.map.MBmap.setLanguage(this.map.mapLocale);
    }
  }
};
</script>

<style lang="scss">
html, body, #app-vue {
  height: 100%;
  width: 100%;
  margin:0;
  padding: 0;
  overflow: hidden!important;
}

:root {
  --amplify-primary-color: #00a644;
  --amplify-primary-tint: #00a644;
  --amplify-primary-shade: #00a644;
}

.custom-sign-in {
  display: block;
  position: absolute;
  text-align: center;
  top: 15em;
}
.signin-class {
  background-color: #00a644;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.amplify-authenticator {
  height: 35em;
  min-height: 35em;
  max-height: 35em;
  display: block;
}
.border-top-radius-class {
  border-top-left-radius: 5px 5px;
  border-top-right-radius: 5px 5px;
  border-bottom-left-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
}
.border-bottom-radius-class {
  border-bottom-left-radius: 5px 5px;
  border-bottom-right-radius: 5px 5px;
  border-top-left-radius: 0px 0px;
  border-top-right-radius: 0px 0px;
}
/* change scrollbar style */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: #eeeeee;
}
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
}
.mapboxgl-ctrl-logo { opacity: 0.1 !important }

.containerBox {
  height: 100%;
  overflow-y: auto;
}

.v-application--wrap {
  min-height: 100%!important;
}
</style>

<template>
  <div>
    <v-flex
      offset-lg1
      offset-xl1
      class="flex-margin"
    >
      <v-overlay :value="policy.overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <v-card-title class="titleHeader">
          <div class="d-flex flex-column containerText">
            <h1 class="title-color">
              {{ $t(policy.translationPath) }}
            </h1>
            <h4 class="description-color">
              {{ $t(policy.translationPathDescription) }}
            </h4>
          </div>
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          v-for="hierarchyItem in policy.hierarchyItems"
          :key="hierarchyItem.node_id"
          class="py-2 px-4"
        >
          <hierarchy
            :father="hierarchyItem.father_id"
            :label="`ServiceCard.${hierarchyItem.node_id}.title`"
            :description="hierarchyItem.node_description"
            :link="hierarchyItem.external_link"
            :s3="hierarchyItem.path_s3_bucket"
            :children="hierarchyItem.children"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hierarchy from '@/components/utils/Hierarchy.vue';

export default {
  components: {
    Hierarchy
  },
  data() {
    return {
      search: '',
      hierarchyItems: [],
      hierarchyItemsLinear: [],
      app_user_id: ''
    };
  },
  computed: {
    ...mapState(['policy'])
  },
  watch: {
    $route() {}
  },
  mounted() {
    if (this.policy.enabledServicesPolicy.length == 0) {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions([]),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>

h1.title-color {
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  font-size: 22px;
}

.title-color {
  color: #0ca344;
}
.description-color {
  font-size: 18px !important;
  color: #3d3d3d;
  font-weight: 400;
}
.viewMargin {
  margin-left: 6em !important;
}
.titleHeader {
  background-color: #f4f3f3;
  align-items: start;
}
.containerText {
  max-width: calc(100% - 64px);
}
</style>

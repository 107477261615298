<template>
  <div>
    <div
      id="map"
      class="map fill-height"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';


export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['map'])
  },

  async mounted() {
    await this.initiateLLMapAction();
  },
  methods: {
    ...mapActions(['initiateLLMapAction'])
  }
};
</script>

<style scoped>
.map {
  background-color: #aad3df;
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 0;
}
</style>

import {
  getAppUser,
  updateAppUser,
  getGlobalLayer,
  listServiceManuals,
  listCustomerManuals,
  getDefaultService
} from '@/api/api.js';
import { i18n } from '@/plugins/i18n';

// --------------------- STATE --------------------------

export const state = {
  headers: [
    {
      text: 'APP USER ID',
      align: 'start',
      sortable: false,
      value: 'app_user_id'
    },
    { text: 'Name', value: '_name' },
    { text: 'Surname', value: 'surname' },
    { text: 'Email', value: 'email' },
    { text: 'Phone', value: 'phone' },
    { text: 'Language', value: 'language' },
    { text: 'State', value: '_state', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  aUsers: [],
  app_user_id: '',
  crm_id: '',
  app_user_data: null,
  username: '',
  customerManuals: [],
  serviceAUser: [],
  setSelectedPolicies: [],
  listAppUserEnabledServices: [],
  listAppUserEnabledServicesReady: false,
  globalLayers: [],
  storeAppUserForm: {},
  defaultService: {}
};

// ------------------------------ MUTATIONS -----------------------------

export const mutations = {
  GET_APP_USER(state, data) {
    state.app_user_data = data;
  },
  UPDATE_APP_USER(state, user) {
    state.aUsers.forEach(element => {
      if (element.app_user_id == user.data.updateAppUser.app_user_id) {
        element._name = user.data.updateAppUser._name;
        element.surname = user.data.updateAppUser.surname;
        element.email = user.data.updateAppUser.email;
        element.phone = user.data.updateAppUser.phone;
        element.language = user.data.updateAppUser.language;
        element.user_map_style = user.data.updateAppUser.user_map_style;
      }
    });
  },
  GET_GLOBAL_LAYER(state, layersArray) {
    layersArray.forEach(globalLayer => {
      if (state.globalLayers.findIndex(item => item.global_layer_id === globalLayer.global_layer_id) === -1) {
        state.globalLayers.push(globalLayer);
      }
    });
  },
  LIST_ENABLED_SERVICES(state, data) {
    state.listAppUserEnabledServices = [];
    state.listAppUserEnabledServices = data;
  },
  LIST_CUSTOMER_MANUALS(state, data) {
    state.customerManuals = [];
    state.customerManuals = data.sort((a, b) => (i18n.t(`${a.label_code}`).localeCompare(i18n.t(`${b.label_code}`), 'en', { numeric: true })));
  },
  GET_DEFAULT_SERVICE(state, obj) {
    const { scope, data } = obj;

    if (scope) {
      state.defaultService[scope] = data[scope];
    } else {
      state.defaultService = data;
    }
  }
};

// ---------------------- GETTERS ---------------------
export const getters = {
  getHeaders: () => {
    const new_headers = [...state.headers];
    new_headers.pop();

    return new_headers;
  }
};

// ------------------------- ACTIONS -----------------------------

export const actions = {
  async storeUpdatedAppUser({ dispatch, commit }, app_user) {
    try {
      const userToUpdate = await updateAppUser(
        app_user.app_user_id,
        app_user._name,
        app_user.surname,
        app_user.email,
        app_user.phone,
        app_user.language,
        app_user.user_map_style
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'user data updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_APP_USER', userToUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedAppUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeAppUser({ commit, dispatch }, app_user_id) {
    try {
      const app_users = await getAppUser(app_user_id);
      commit('GET_APP_USER', app_users.data.getAppUser);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAppUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGlobalLayers({ dispatch, commit }, service_id) {
    const services = service_id ? [...new Set(service_id)] : [];

    try {
      const resp = await getGlobalLayer(services);
      commit('GET_GLOBAL_LAYER', resp.data.getGlobalLayer.sort((a, b) => (a.layer_name.toUpperCase() > b.layer_name.toUpperCase() ? 1 : -1)));
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGlobalLayers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeEnabledServices({ dispatch, commit, state }, app_user_id) {
    try {
      state.listAppUserEnabledServicesReady = false;
      const resp = await listServiceManuals(app_user_id);
      commit('LIST_ENABLED_SERVICES', resp.data.listServiceManuals);
      state.listAppUserEnabledServicesReady = true;
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeEnabledServices / listServiceManuals',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeCustomerManuals({ dispatch, commit }, crm_id) {
    try {
      const resp = await listCustomerManuals(crm_id);
      commit('LIST_CUSTOMER_MANUALS', resp.data.listCustomerManuals);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCustomerManuals',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeDefaultService({ dispatch, commit }, data) {
    try {
      const SCOPE = data.app_scope?.replace('-', '');
      const resp = await getDefaultService(data);
      commit('GET_DEFAULT_SERVICE', { scope: SCOPE, data: resp.data.getDefaultService });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDefaultService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@aws-amplify/ui-vue';
import { currentConfig } from './api/api.js';
import 'vuex-toast/dist/vuex-toast.css';
import { i18n } from './plugins/i18n';
import { GA_CODE } from '@/constants.js';
import VueGtag from 'vue-gtag';
import moment from 'moment-business-days';

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

// Register a global custom directive called `v-blur` that prevents focus
Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  }
});

Vue.use(VueGtag, {
  config: { id: GA_CODE }
}, router);

new Vue({
  async beforeCreate() {
    // try {
    //   const browserLanguage = navigator.language.split("-");
    //   await loadLanguageAsync(browserLanguage[0]);
    // } catch (error) {
    //   if (error !== "No current user") {
    //     log.info("translations error", error);
    //   }
    // }
  },
  router,
  store,
  vuetify,
  currentConfig,
  i18n,
  render: h => h(App)
}).$mount('#app');

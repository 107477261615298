<template>
  <v-flex class="flex-margin">
    <object
      :data="url"
      type="application/pdf"
      width="100%"
      height="100%"
    >
      <p>Your web browser doesn't have a PDF plugin.
        <a :href="url">click here to download the PDF file.</a></p>
    </object>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';

export default {
  data() {
    return {
      url: ''
    };
  },
  computed: {
    ...mapState([])
  },
  watch: {
    $route: 'pdfLink'
  },
  async created() {
    await this.pdfLink();
  },
  methods: {
    ...mapActions([]),
    async pdfLink() {
      const document = this.$route.name === 'Disclaimer'
        ? 'disclaimer/Disclaimer.pdf'
        : 'disclaimer/Disclaimer_Meteotrigger_2.0.pdf';

      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: document
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);
      this.url = response;
    }
  }
};
</script>

<style scoped>
.flex-margin {
  margin-right: 50px;
  margin-top: 0;
  height: 100vh;
  width: 100vw;
  padding-left: 84px
}
</style>

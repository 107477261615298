var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-navigation-drawer',{attrs:{"permanent":"","fixed":"","mini-variant":_vm.mobileBreakpoints(),"mini-variant-width":40,"color":"RMblack","height":"100%","width":"100","clipped":""}},[_c('v-list',[_c('v-list-item',{staticClass:"mt-2 text-center margin-logo"},[_c('v-list-item-avatar',[_c('img',{staticClass:"logo-size",staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/radarmeteo-marchio.png')},on:{"click":function($event){return _vm.resetDrawer()}}})])],1),(!_vm.mobileBreakpoints())?_c('span',{staticClass:"d-block text-center mt-2",staticStyle:{"font-size":"12px","color":"white"}},[_vm._v(" "+_vm._s(_vm.updatedDate)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.updatedHour)+" ")]):_vm._e()],1),_c('v-divider',{staticClass:"custom-divider"}),_c('v-list',{attrs:{"dense":""}},[(!_vm.app_user.listAppUserEnabledServicesReady)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-cell@3"}}):_vm._e(),_c('v-list-item-group',{class:_vm.drawer.drawerTitle === 'NavigationDrawer.archive' || (_vm.$route.params.id === 'Archive' && !_vm.drawer.drawerVisibility)
            ? 'active-item '
            : 'not-active-item'},[(_vm.app_user.listAppUserEnabledServices.some(function (item){
            if(item.service_type === 'WS'){
              return item.service_ws_group.some(function (item){ return item === 'Archive'; })
            }
          }))?_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawerWorkspace('Archive', 'NavigationDrawer.archive')}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t('NavigationDrawer.archive'),"path":_vm.archiveSvg}})]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"icon-description"},[_vm._v(" "+_vm._s(_vm.$t('NavigationDrawer.archive'))+" ")])]},proxy:true}],null,false,3239950791)})],1):_vm._e()]),_c('v-list-item-group',{class:_vm.drawer.drawerTitle === 'NavigationDrawer.real_time' || (_vm.$route.params.id === 'Real-Time' && !_vm.drawer.drawerVisibility)
            ? 'active-item '
            : 'not-active-item'},[(_vm.app_user.listAppUserEnabledServices.some(function (item){
            if(item.service_type === 'WS'){
              return item.service_ws_group.some(function (item){ return item === 'Real-Time'; })
            }
          }))?_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawerWorkspace('Real-Time', 'NavigationDrawer.real_time' )}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t('NavigationDrawer.real_time'),"path":_vm.realTimeSvg}})]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"icon-description"},[_vm._v(" "+_vm._s(_vm.$t('NavigationDrawer.real_time'))+" ")])]},proxy:true}],null,false,3610649994)})],1):_vm._e()]),_c('v-list-item-group',{class:_vm.drawer.drawerTitle === 'NavigationDrawer.prevision' || (_vm.$route.params.id === 'Forecast' && !_vm.drawer.drawerVisibility)
            ? 'active-item '
            : 'not-active-item'},[(_vm.app_user.listAppUserEnabledServices.some(function (item){
            if (item.service_type === 'WS') {
              return item.service_ws_group.some(function (item){ return item === 'Forecast'; })
            }
          }))?_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawerWorkspace('Forecast', 'NavigationDrawer.prevision' )}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t('NavigationDrawer.prevision'),"path":_vm.forecastSvg}})]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"icon-description"},[_vm._v(" "+_vm._s(_vm.$t('NavigationDrawer.prevision'))+" ")])]},proxy:true}],null,false,4217256030)})],1):_vm._e()]),_c('v-divider',{staticClass:"custom-divider"}),(!_vm.app_user.listAppUserEnabledServicesReady)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-cell@2"}}):_vm._e(),_vm._l((_vm.navigationDrawerServices),function(navigationItem){return _c('v-list-item-group',{key:navigationItem.translationPath,class:_vm.drawer.drawerTitle == navigationItem.translationPath
            ? 'active-item '
            : 'not-active-item'},[(_vm.app_user.listAppUserEnabledServices.some(function (item){ return item.service_type === 'URL' || item.service_type === 'DOC' || item.service_type === 'ALERT EX-POST' || item.service_type === 'REPORTS' || item.service_type === 'REPORT-HUB'; }))?_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawer(navigationItem.translationPath, navigationItem.service_type)}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t(("" + (navigationItem.translationPath))),"path":navigationItem.svg}})]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"icon-description"},[_vm._v(" "+_vm._s(_vm.$t(navigationItem.translationPath))+" ")])]},proxy:true}],null,true)})],1):_vm._e()])}),_vm._l((_vm.navigationDrawerOperativeSupport),function(navigationItem){return _c('v-list-item-group',{key:navigationItem.translationPath,class:_vm.drawer.drawerTitle == navigationItem.translationPath
            ? 'active-item '
            : 'not-active-item'},[(_vm.app_user.listAppUserEnabledServices.some(function (item) { return item.service_type === 'TICKET'; }))?_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawer(navigationItem.translationPath, navigationItem.service_type)}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t(("" + (navigationItem.translationPath))),"path":navigationItem.svg}})]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"icon-description"},[_vm._v(" "+_vm._s(_vm.$t(navigationItem.translationPath))+" ")])]},proxy:true}],null,true)})],1):_vm._e()])})],2),_c('v-list',{staticClass:"bottom-icon",attrs:{"dense":""}},[_vm._l((_vm.navigationDrawerSettings),function(navigationItem){return _c('v-list-item-group',{key:navigationItem.translationPath,class:_vm.drawer.drawerTitle == navigationItem.translationPath
            ? 'active-item '
            : 'not-active-item'},[_c('span',{on:{"click":function($event){return _vm.openDynamicNavigationDrawer(navigationItem.translationPath)}}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t(("" + (navigationItem.translationPath))),"path":navigationItem.svg}})]},proxy:true}],null,true)})],1)])}),_c('v-divider',{staticClass:"custom-divider"}),_c('v-list-item-group',[_c('div',{on:{"click":_vm.signOut}},[_c('ListItemIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIcon',{attrs:{"color1":'#000000',"color2":'#FFFFFF',"title":_vm.$t('NavigationDrawer.logOut'),"path":_vm.logoutSvg}})]},proxy:true}])})],1)])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
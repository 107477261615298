<template>
  <div>
    <v-flex
      offset-lg-1
      class="flex-margin"
    >
      <v-card>
        <v-card-title class="main-title">
          <span>Manuali</span>
          <v-spacer />
          <v-icon
            v-if="mobileBreakpoints()"
            class="text-right"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-card
            v-for="(manual, index) in app_user.customerManuals"
            :key="index"
            class="mt-5"
          >
            <v-card-title>
              <div
                class="d-flex flex-nowrap justify-space-between align-center"
                style="cursor: pointer; width: 100%;"
                @click="downloadManual(manual.manual_s3_path)"
              >
                <div class="d-flex flex-wrap">
                  <div class="title-color mr-8 pb-0 pt-0">
                    {{ $t(manual.label_code) }}
                  </div>
                  <div class="title-color mr-8 pb-0 pt-0">
                    <span class="children-color description-size">
                      {{ manual.manual_s3_path.split("/manuals/")[1] }}
                    </span>
                  </div>
                </div>
                <SvgIcon
                  :color="'#0ca344'"
                  :color1="'#0ca344'"
                  :color2="'#0ca344'"
                  :title="manual.manual_s3_path.split('/manuals/')[1]"
                  :path="toLinkSvg"
                  style="height: 24px!important;"
                />
              </div>
            </v-card-title>
          </v-card>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';
import { mapActions, mapState } from 'vuex';
import { svgToLink } from '@/svg.js';

export default {
  components: {
    SvgIcon
  },
  props: {
  },
  data() {
    return {
      toLinkSvg: svgToLink
    };
  },
  computed: {
    ...mapState(['file_transfer', 'app_user'])
  },
  methods: {
    ...mapActions([]),
    async downloadManual(path) {
      const params = {
        Bucket: S3_BUCKETS.customer(),
        Key: path
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    },
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>
.flex-margin{
  margin-right: 2%;
  margin-top: 2%
}
.title-color {
  color: #0ca344;
}
.main-title {
  color: #0ca344;
  background-color: #f4f3f3;
}
.v-expansion-panel::before {
  box-shadow: none;
}
@media (max-device-width:1023px) {
  .title-color {
    font-size: 1rem !important;
  }
  .description-size{
    font-size: 13px !important;
  }
}
.children-color {
  font-size: 18px;
  color: #3d3d3d;
}
</style>

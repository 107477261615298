import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LANGUAGES } from '@/constants.js';
import userLang from '../locales/userLang.json';
import defaultIt from '@/locales/defaultIt.json';
import defaultEn from '@/locales/en.json';
import { listTranslations } from '@/api/api.js';

Vue.use(VueI18n);
const _messages = {
  default: defaultIt,
  en: defaultEn,
  it: defaultIt
};

const loadedLanguages = LANGUAGES;
const browserLang = navigator.language.split('-');

export const i18n = new VueI18n({
  locale: browserLang || 'default',
  fallbackLocale: browserLang || 'default',
  messages: _messages,
  silentTranslationWarn: true
});

const setI18nLanguage = async lang => {
  const resp = await listTranslations(lang);
  Object.assign(userLang, _messages.default);
  resp.data.listTranslations.forEach(translation => {
    Object.assign(userLang, {
      [translation.label_code]: translation.label_value
    });
  });
  i18n.setLocaleMessage(lang, userLang);
  i18n.locale = lang;
};

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
}

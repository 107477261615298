<template>
  <div :class="children.length == 0 && father != '' ? 'pt-4' : ''">
    <v-expansion-panels>
      <v-expansion-panel
        :readonly="children.length == 0 ? true : false"
        :style="{ background: $vuetify.theme.themes['light'].background }"
      >
        <v-expansion-panel-header
          :expand-icon="children.length > 0 ? '$expand' : ''"
          :class="children.length >= 0 && father != '' ? 'pa-0' : 'pa-4'"
        >
          <!-- is a middle item -->
          <div v-if="children.length > 0 && father != ''">
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex child-style title-color pa-0 pr-2"
                >
                  {{ $t(label) }}
                </v-col>
                <v-col
                  v-if="description"
                  cols="12"
                  md="6"
                  class="d-flex child-description-style"
                  :class="description != '' ? 'pa-0' : 'pt-0'"
                >
                  {{ $t(description) }}
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <!-- is a child item -->
          <div v-else-if="children.length == 0 && father != ''">
            <v-card>
              <v-card-title>
                <v-row class="ma-0 justify-space-between">
                  <v-col
                    cols="8"
                    md="4"
                    class="child-style pa-0 pr-2"
                  >
                    {{ $t(label) }}
                  </v-col>
                  <v-col
                    v-if="$t(description) !== ''"
                    cols="8"
                    md="6"
                    class="pb-0 pt-0"
                  >
                    <span class="child-description-style">
                      {{ $t(description) }}
                    </span>
                  </v-col>
                  <v-col
                    v-if="link != '' && link != undefined"
                    cols="4"
                    md="2"
                    class="pa-0 d-flex justify-end"
                    @click="goToLink(link)"
                  >
                    <SvgIcon
                      :color="'#0ca344'"
                      :color1="'#0ca344'"
                      :color2="'#0ca344'"
                      :title="link"
                      :path="goToLinkSvg"
                    />
                  </v-col>
                  <v-col
                    v-if="s3 != '' && s3 != undefined"
                    cols="4"
                    md="2"
                    class="pa-0 d-flex justify-end"
                    @click="dispatchDownloadItem(s3)"
                  >
                    <v-icon size="34">
                      mdi-file-pdf-box
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </div>
          <!-- is a father item -->
          <div v-else>
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="father-style"
                  :class="description != '' ? 'pb-0' : 'pa-0'"
                >
                  {{ $t(label) }}
                </v-col>
                <v-col
                  v-if="description != ''"
                  cols="12"
                  md="4"
                  class="father-description-style"
                >
                  {{ $t(description) }}
                </v-col>
              </v-row>
            </v-card-title>
          </div>
        </v-expansion-panel-header>
        <v-divider v-if="father == ''" />
        <v-expansion-panel-content
          v-if="children"
          class="column-flex-justify"
        >
          <hierarchy
            v-for="node in children"
            :key="node.node_id"
            :father="node.father_id"
            :children="node.children"
            :label="`ServiceCard.${node.node_id}.title`"
            :description="`ServiceCard.${node.node_id}.description`"
            :link="node.external_link"
            :s3="node.path_s3_bucket"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { S3_BUCKETS } from '@/constants.js';
import { mapActions, mapState } from 'vuex';
import { svgToLink } from '@/svg.js';

export default {
  name: 'Hierarchy',
  components: {
    SvgIcon
  },
  props: {
    father: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    s3: {
      type: String,
      default: null
    },
    children: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showChildren: false,
      goToLinkSvg: svgToLink
    };
  },
  computed: {
    ...mapState(['file_transfer'])
  },
  methods: {
    ...mapActions(['downloadItem']),
    dynamicClass(node) {
      if (!node.children.length) {
        return 'flex-grow-class full-width-class';
      } else {
        return 'flex-grow-class half-width-class';
      }
    },
    goToLink(link) {
      if (link.includes('http') || link.includes('https')) {
        window.open(link, '_blank');
      } else {
        window.open(`https://${link}`, '_blank');
      }
    },
    async dispatchDownloadItem(s3Key) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: s3Key
      };
      await this.downloadItem(params);

      window.open(this.file_transfer.downloadLink, '_blank');
    }
  }
};
</script>

<style scoped>
.title-color {
  color: #0ca344;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.father-style {
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.father-description-style {
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
}

.child-description-style {
  font-size: 16px !important;
  font-weight: normal;
  color: #3d3d3d;
}

.child-style {
  font-size: 16px;
  color: #0ca344;
}
</style>

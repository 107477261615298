<template>
  <div>
    <!-- <GmapMap /> -->
    <Leaflet v-if="$route.path.includes('alert-ex-post')" />
    <Map v-else />
  </div>
</template>

<script>

import Map from '@/components/core/Map.vue';
import Leaflet from '@/components/core/Leaflet.vue';

export default {
  components: {
    Map,
    Leaflet
  }
};
</script>

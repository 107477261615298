import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        RMprimaryDark: '#36C86C',
        RMprimary: '#00A644',
        RMwhite: '#000000',
        RMblack: '#FFFFFF',
        background: '#FAFAFA'
      },
      light: {
        RMprimary: '#00A644',
        RMprimaryDark: '#136D33',
        RMblack: '#000000',
        RMwhite: '#FFFFFF',
        background: '#FAFAFA'
      }
    }
  }
});

<template>
  <svg
    :width="small ? '1em' : '1.7em'"
    :height="small ? '1em' : '1.7em'"
    :style="getRotate()"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="icon-size"
  >
    <title>{{ title }}</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        :style="getColor()"
        :d="path"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: String,
      default: '0'
    },
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#000000'
    },
    color1: {
      type: String,
      default: '#FFFFFF'
    },
    color2: {
      type: String,
      default: '#000000'
    },
    active: {
      type: Boolean,
      default: true
    },
    path: {
      type: String,
      default:
        'M4.92840293,4.92970293 C3.05340105,6.80600481 2,9.34904068 2,12.0013767 C2,14.6537127 3.05470105,17.1967152 4.92970293,19.0717171 C6.8047048,20.9467189 9.34770735,22.00012 12.0000433,22.00012 C14.6523793,22.00012 17.1953819,20.9467189 19.0717171,19.0717171 C20.9467189,17.1967152 22.00012,14.6537127 22.00012,12.0013767 C22.00012,9.34904068 20.9467189,6.80603814 19.0717171,4.92970293 C17.1967152,3.05470105 14.6523793,2 12.0000433,2 C9.34770735,2 6.8047048,3.05470105 4.9283696,4.92970293 L4.92840293,4.92970293 Z M6.81513815,6.81643815 C8.02737269,5.60810361 9.62374096,4.86070286 11.3281427,4.70446937 C13.0325444,4.54823588 14.7381461,4.99353299 16.1484808,5.96357063 L5.9621373,16.1535808 C4.99076966,14.7421127 4.54286921,13.0350777 4.6991027,11.3292427 C4.85404952,9.62350762 5.60143693,8.02583936 6.81237148,6.81357148 L6.81513815,6.81643815 Z M17.1848152,17.1861152 C15.9738806,18.3957497 14.3775124,19.1431505 12.673144,19.299384 C10.968709,19.4543308 9.2631406,19.0090203 7.85147252,18.0402827 L18.0351494,7.84627251 C19.006517,9.25774059 19.4544175,10.9647756 19.298184,12.6706107 C19.1432371,14.3763457 18.3958497,15.974014 17.1849152,17.1862819 L17.1848152,17.1861152 Z'
    }
  },
  methods: {
    getColor() {
      if (!this.active) {
        return 'fill: grey';
      } else {
        return this.$vuetify.theme.dark
          ? `fill: ${this.color1};`
          : `fill: ${this.color2};`;
      }
    },
    getRotate() {
      return `transform: rotate(${this.rotate}deg)`;
    }
  }
};
</script>

<style scoped>
@media (min-device-width:280px) and (max-device-width:767px){
    .icon-size{
      width: 25px !important;
  }
}
</style>

import { render, staticRenderFns } from "./NavigationSideBar.vue?vue&type=template&id=1263d597&scoped=true&"
import script from "./NavigationSideBar.vue?vue&type=script&lang=js&"
export * from "./NavigationSideBar.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSideBar.vue?vue&type=style&index=0&id=1263d597&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1263d597",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemGroup,VListItemTitle,VNavigationDrawer,VOverlay,VProgressCircular,VSpacer,VToolbar,VToolbarTitle})

<template>
  <div class="section-overflow">
    <v-card
      class="ma-2"
      color="RMwhite"
    >
      <v-overlay :value="other_services.alertExp_overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title class="pa-0 pl-2 py-4 d-flex green--text text--accent-4 font-weight-bold flex-nowrap text-subtitle-1 text-md-h6">
        {{ $t('NavigationSideBar.alertExPost') }}
        <v-spacer />
        <alert-ex-post-popup
          :is-pdf-report="true"
          :existing-start-date="start"
          :existing-end-date="end"
          @dateValues="setDateValues"
        />
      </v-card-title>

      <v-divider class="pb-4" />

      <v-card-text class="pa-2">
        <v-autocomplete
          v-model="other_services.selectedCity"
          :items="other_services.city"
          item-text="nome_comune"
          :label="$t('AlertExPost.city')"
          :search-input.sync="other_services.searchCityInput"
          return-object
          solo
          @keyup="citySort()"
          @click="citySort()"
          @change="other_services.searchCityInput = ''; citySort()"
        />
        <v-select
          v-model="other_services.selectedAdversity"
          :items="other_services.adversity"
          item-text="nome"
          :label="$t('AlertExPost.adversity')"
          item-color="success"
          return-object
          solo
        />
        <v-row>
          <v-col class="col-6">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  :label="$t('ServiceCard.startDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                :max="nowDate"
                :locale="app_user.app_user_data[0].language"
                first-day-of-week="1"
                @input="setStartDate()"
              />
            </v-menu>
          </v-col>
          <v-col class="col-6">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              :disabled="(typeof startDate) !== 'string'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  :label="$t('ServiceCard.endDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  :disabled="(typeof startDate) !== 'string'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                :picker-date.sync="startDate"
                no-title
                :min="startDate"
                :max="nowDate"
                :locale="app_user.app_user_data[0].language"
                first-day-of-week="1"
                @input="setEndDate()"
              />
            </v-menu>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            class="mb-4"
            color="#0da344"
            style="color:white"
            small
            :disabled="checkFields"
            @click="search()"
          >
            {{ $t('AlertExPost.search') }}
          </v-btn>
        </div>

        <div>
          <!-- v-if="other_services.notFound" -->
          <!-- <v-alert
          v-model="other_services.notFound"
          dense
          type="warning"
          icon="mdi-alert-outline"
          class="mt-4"
          transition="scale-transition"
          dismissible
        >
          {{ $t('AlertExPost.noData') }}
        </v-alert> -->
          <!-- <div v-if="!other_services.notFound && other_services.isSearch"> -->
          <div v-if="other_services.isSearch">
            <v-row class="mb-2">
              <v-col
                cols="6"
                class="mt-2"
              >
                <b class="RMprimary-color">
                  {{ updatedDateText }}
                </b>
              </v-col>
              <v-col
                v-for="player of playerIcons"
                :key="player"
                cols="3"
              >
                <v-btn
                  fab
                  depressed
                  small
                  :disabled="player === 'mdi-skip-backward' ? prevBtnIsDisabled : nextBtnIsDisabled"
                >
                  <v-icon
                    dark
                    @click="skip(player === 'mdi-skip-backward' ? 'prev' : 'next')"
                  >
                    {{ player }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-alert
              v-model="other_services.notFound"
              dense
              type="warning"
              icon="mdi-alert-outline"
              class="mt-4"
              transition="scale-transition"
              dismissible
            >
              {{ $t('AlertExPost.noData') }}
            </v-alert>
            <alert-ex-post-popup :is-pdf-report="false" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getFormattedCurrentDate, getFormattedDate, updatedDateWithTimeStep } from '@/utils.js';

export default {
  components: {
    AlertExPostPopup: () => import('@/components/utils/AlertExPostPopup')
  },
  props: {
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      playerDate: null,
      nowDate: new Date().toISOString().slice(0, 10),
      start: '',
      end: '',
      n: 0,
      prevBtnIsDisabled: false,
      nextBtnIsDisabled: false,
      startDateMenu: false,
      endDateMenu: false,
      alert: false,
      timeStepArray: [],
      playerIcons: ['mdi-skip-backward', 'mdi-skip-forward']
    };
  },
  computed: {
    ...mapState(['other_services', 'map', 'app_user', 'drawer']),
    updatedDateText() {
      return getFormattedCurrentDate(new Date(this.timeStepArray[this.n])).split(' ')[0];
    },
    checkFields() {
      return !((this.other_services.selectedCity && this.other_services.selectedAdversity && this.startDate && this.endDate));
    }
  },
  watch: {
  },
  async mounted() {
    this.other_services.alertExp_overlay = true;
    await this.getAlertExPostApi('elenco-comuni');
    await this.getAlertExPostApi(`elenco-variabili/${this.app_user.app_user_data[0].ragione_sociale}`);
    this.other_services.alertExp_overlay = false;
  },
  methods: {
    ...mapActions(['getAlertExPostApi', 'searchBtn', 'skipBtn']),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    },
    citySort() {
      const text = this.other_services.searchCityInput;

      if (text === '' || text === undefined || text === null) {
        this.other_services.city.sort(function (a, b) {
          return a.nome_comune.toLowerCase() < b.nome_comune.toLowerCase() ? -1 : 1;
        });
      } else {
        this.other_services.city.sort(function (a, b) {
          return a.nome_comune.toLowerCase().indexOf(text) < b.nome_comune.toLowerCase().indexOf(text) ? -1 : 1;
        });
      }
    },
    setStartDate() {
      this.startDateMenu = false;
      this.start = this.startDate;
    },
    setEndDate() {
      this.endDateMenu = false;
      this.end = this.endDate;
    },
    setDateValues (model, value) {
      switch (model) {
        case 'start':
          this.start = value;
          break;
        case 'end':
          this.end = value;
          break;
        default:
          break;
      }
    },
    search() {
      this.playerDate = this.endDate;
      this.n = 0;

      this.prevBtnIsDisabled = (this.startDate === this.endDate);
      this.nextBtnIsDisabled = true;
      this.searchBtn({ startDate: this.startDate, endDate: this.endDate });

      this.timeStepArray = [];
      let dateToAdd = new Date(this.startDate).setUTCHours(12);
      this.timeStepArray.unshift(new Date(dateToAdd).toISOString());

      while (new Date(dateToAdd) < new Date(this.endDate).setUTCHours(12)) {
        dateToAdd = updatedDateWithTimeStep(1, 'dynamic_days', 'add_step', dateToAdd);
        dateToAdd = new Date(dateToAdd).setUTCHours(12);
        this.timeStepArray.unshift(new Date(dateToAdd).toISOString());
      }

      this.$gtag.event('Dati richiesta alert ex-post su mappa', {
        event_category: 'ALERT EX-POST',
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.other_services.selectedCity.nome_comune} - ${this.other_services.selectedAdversity.nome} - ${this.startDate} to ${this.endDate}`
      });
    },
    skip(btn) {
      const lastIndex = this.timeStepArray.length - 1;

      if (btn === 'prev') {
        ++this.n;
        this.nextBtnIsDisabled = false;
        this.prevBtnIsDisabled = this.n === lastIndex;
      } else {
        --this.n;
        this.prevBtnIsDisabled = false;
        this.nextBtnIsDisabled = this.n === 0;
      }

      this.playerDate = this.timeStepArray[this.n];
      this.skipBtn(this.playerDate);
    }
  }
};
</script>

<style scoped>
.section-overflow {
  overflow-y: auto;
  height: 100% !important;
}
.search-location {
  font-size:16px;
  color: black;
  background-color: white;
  width: 100%;
  height: 100%;
}
.search-location:focus {
  outline: none;
}
.inputDiv {
  height: 55px;
}
.RMprimary-color {
  color: #00A644;
}
::-webkit-scrollbar {
  border-radius: 12px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: rgb(0, 166, 68, 1);
}
</style>
